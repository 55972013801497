import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import url, { bsurl, clurl, stressurl } from '../url';
// import MainContent from './MainContent';
import BpIcon from '../../images/BP Icons.png'
import BasicIcon from '../../images/Basic icon.png'
import BlueAppointIcon from '../../images/UserHMSAssets/Appointments1@2.1x.png'
import ScanCountIcon from '../../images/UserHMSAssets/Total Scans@2.1x.png'
import PendingAppointmentIcon from '../../images/UserHMSAssets/Pending Appointments@2.1x.png'
// import videoicon from '../../images/UserHMSAssets/Calls@2.1x.png'
import videoicon from '../../images/UserHMSAssets/NewIcons/Asset 33@2.1x.png'
// import videoicongray from '../../images/UserHMSAssets/Calls2@2.1x.png'
import videoicongray from '../../images/UserHMSAssets/NewIcons/Asset 34@2.1x.png'
// import videoicongray from '../../images/UserHMSAssets/NewIcons/Asset 34@2.1x.png'
import DocIcon from '../../images/UserHMSAssets/Doctor Icons.png'
import PatientIcon from '../../images/UserHMSAssets/Patient Icons.png'

import DocNav from './DocNav';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Dashboard = () => {
  const [myappointments,setMyAppointments]=useState({})
  const dt= Date()
  const [doctors,setDoctors]=useState([])
  const {auth}= useContext(AuthContext)
  let [count, setCount] = useState(0);

  const [totalscan,setTotalScan]=useState(0)
  const [pending, setPending]=useState('')

  // console.log('auth')
  // console.log(auth)

 useEffect(()=>{
  getTotalScan()
  getPendingAppointment()


  const intervalId = setInterval(() => {
    getPationNot()
    getTotalScan()
    getPendingAppointment()
   
  }, 1000 * 5) // in milliseconds
  return () => clearInterval(intervalId)
 

 },[])

 
  useEffect(()=>{
    const config = {
        headers: { Authorization: `Bearer ${auth.token}` }
    };
    axios.get(url+ 'hms/doctor/doctors',config).then(res=>{
        // console.log(res)
        setDoctors(res.data.result.data)
    }).catch(e=>{

        console.log(e)

    })

},[])
  useEffect(()=>{
    getPationNot()
   

  },[])

  const getPationNot=()=>{
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
  };
    axios.get(url+'hms/doctor/getPatientNotification?patient_id='+auth.userid,config)
    .then(res=>{
      // console.log(res)
      setMyAppointments(res.data.result.data)
    }).catch(e=>{
      console.log(e)
    }).then(r=>{

    })
  }
  const getTotalScan=()=>{
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
  };
    axios.get(url+'hms/doctor/getCountScans',config)
    .then(res=>{

      setTotalScan(res.data.result)
      console.log(res.data.result)
      // setMyAppointments(res.data.result.data)
    }).catch(e=>{

      // alert('')
      console.log(e)
    }).then(r=>{

    })
  }
  const getPendingAppointment=()=>{
    const config = {
      headers: { Authorization: `Bearer ${auth.token}` }
  };
    axios.get(url+'hms/doctor/getPatientRequestCount',config)
    .then(res=>{

      setPending(res.data.result)
      console.log(res.data.result)
      // setMyAppointments(res.data.result.data)
    }).catch(e=>{

      // alert('')
      console.log(e)
    }).then(r=>{

    })
  }

  const appointments=(
    <div className='flex flex-row space-x-5 '>
                <div className='flex flex-col w-12 rounded-lg'>
                  <div className='flex flex-auto h-8 bg-gray-700 text-white  rounded-t-lg'>
                    <span className='mx-auto text-2xl font-black'>
                    {moment(myappointments.appointment_date).format('D')}
                    </span>

                  </div>
                  <div className='bg-blue-900 flex flex-auto text-white w-12 rounded-b-lg  '>
                   <span className='mx-auto'>
                   {moment(myappointments.appointment_date).format('MMMM')}
                     </span>

                  </div>

                </div>

                <div className='flex flex-row space-x-2'>
                  <div className='mr-4 hidden md:inline'>
                  <div class="relative w-14 h-14 ">
                      {/* <img class="rounded-full border-2 border-blue-500 shadow-sm" src='https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-4-470x470.png' alt="user image" /> */}
                      <img class="rounded-full border-2 border-blue-500 shadow-sm h-14" 
                      src={PatientIcon} alt="user image" />
                      <div class="absolute top-0 right-0 h-4 w-4 my-1 border-2 border-white rounded-full bg-green-400 z-2"></div>
                  </div>

                  </div>
                  <div className='flex flex-col ml-6'>
                    <div className='font-bold'>
                    {myappointments.doctor_name}
                      
                    </div>
                    <div className='text-gray-700 text-sm font-semibold'>
                    Family Medicine

                    </div>

                  </div>

              </div>
              <div className='flex flex-row ml-10'>
                <div className='w-12 h-12 mx-10'>
                  {myappointments.meeting_link==''?<>

                  <img src={videoicongray} className=" h-10 " />

                  </>:<>
                  <Link to={`/${myappointments.meeting_link}`}>
                    <div className='flex flex-col space-y-0 py-0 my-0'>
                      <div className='my-p py-0 mb-0 mt-0'>
                      <img src={videoicon} className="py-0 my-0 h-12" />
                      
                      
                      </div>
                      <span className='text-sm space-y-0 my-0 py-0 mt-0 text-gray-500'>
                      Ready
                      </span>

                    </div>
                  
                  </Link>

                  </>}
                
                </div>

              </div>
              <div className='flex flex-row'>
                
                 {moment(myappointments.appointment_date).format('LT')}

              </div>

              </div>
  )
  const renderdoctor=doctors.map((doctor,index)=>(
    <div key={index} className="flex flex-row space-x-4 py-2">
        <div>
        <div class="relative w-16 h-16">
                                <img class="rounded-full border-2 border-blue-500 shadow-sm" src={DocIcon} alt="user image" /> 
                                <div class="absolute top-0 right-0 h-4 w-4 my-1 border-2 border-white rounded-full bg-green-400 z-2"></div>
                            </div>
        </div>
        <div className='flex flex-col w-1/2'>
            <div>
            {doctor.name}

            </div>
            <div className='text-sm'>
            Family Medicine

            </div>
            {/* <div>
            <div class="flex items-center text-sm" >
                <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                <svg class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            </div>

            </div> */}
            
        </div>
        <div>
        <div class="">
        
        <Link to='/appointments'>
        <img src={BlueAppointIcon} className="w-10 h-10" />

        </Link>

        </div>
            
        </div>


    </div>
))
    return ( 
        <div>
          
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-100  text-black ">

      {/* Navigation Here */}
      <DocNav />
    
      
      <div  class="h-full ml-14 mt-14 mb-10 md:ml-64 flex flex-col px-4 space-y-3 ">
          {/* Main content here */}
      {/* <MainContent /> */}
      
      <div className='text-4xl font-bold'>
        <br />
        Patient's Dashboard

      </div>
          <div className='flex md:flex-row flex-wrap md:space-x-3 md:space-y-0 space-y-3 flex-col'>
                <div className='bg-gray-700 rounded-lg flex flex-col text-white font-bold '>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BasicIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Heart Rate, Breathing Rate, and SpO2 Levels
                                {/* Check SpO2 Levels */}

                            </div>
                            
                        </div>
                        
                            {/* <a href='https://patient-test.basic.docsun.health/instructions'> */}
                            <Link to='/instructions'>
                            <div className='flex flex-row'>
                            
                            <div className='text-blue-500 ml-4'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>
                            </div>
                            </Link>

                        

                    </div>
                    <div className='bg-gray-700 rounded-lg flex flex-col text-white font-bold px-3 w-full md:w-auto'>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BpIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Blood Pressure

                            </div>
                            
                        </div>
                        <a href={`${stressurl}/${auth.token}/${dt}/${auth.userid}`}>
                        <div className='flex flex-row'>
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>

                        </div>
                        </a>

                    </div>
                    <div className='bg-gray-700 rounded-lg flex flex-col text-white font-bold px-3 w-full md:w-auto'>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BpIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Blood Sugar

                            </div>
                            
                        </div>
                        <a href={`${bsurl}/${auth.token}/${dt}/${auth.userid}`}>
                        <div className='flex flex-row'>
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>

                        </div>
                        </a>

                    </div>

                    {/* <div className='bg-gray-700 rounded-lg flex flex-col text-white font-bold px-3 md:mx-10'>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BlueAppointIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Schedule an Appointment

                            </div>
                            
                        </div>
                        <Link to='/appointments'>
                        <div className='flex flex-row'>
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>

                        </div>
                        </Link>

                    </div> */}
                    

                 </div>
          <div className='flex md:flex-row flex-wrap md:space-x-60 md:space-y-0 space-y-3 flex-col'>
                
                    <div className='bg-gray-700 rounded-lg flex flex-col text-white font-bold px-3 w-full md:w-auto'>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BpIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Cholesterol

                            </div>
                            
                        </div>
                        <a href={`${clurl}/${auth.token}/${dt}/${auth.userid}`}>
                        <div className='flex flex-row'>
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>

                        </div>
                        </a>

                    </div>
                    <div className='bg-gray-700 rounded-lg flex flex-col text-white font-bold px-3 md:mx-10'>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BlueAppointIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Schedule an Appointment

                            </div>
                            
                        </div>
                        <Link to='/appointments'>
                        <div className='flex flex-row'>
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>

                        </div>
                        </Link>

                    </div>

                 </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 gap-4">
          <div class="bg-white   shadow-lg rounded-md flex items-center justify-between p-3 border-b-4  font-medium group">
            <div class="flex justify-center items-center w-16 h-16 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              {/* <svg width="30" height="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="stroke-current text-blue-800 transform transition-transform duration-500 ease-in-out"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg> */}
              <img src={ScanCountIcon} />
            </div>
            <div class="text-right">
              
              <p className='text-gray-700'>Total Scans</p>
              <p class="text-3xl text-green-500 font-bold">{totalscan}</p>
            </div>
          </div>
          <div class="bg-white  shadow-lg rounded-md flex items-center justify-between p-3 border-b-4    font-medium group">
            <div class="flex justify-center items-center w-16 h-16 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              {/* <svg width="30" height="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="stroke-current text-blue-800  transform transition-transform duration-500 ease-in-out"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"></path></svg> */}
              <img src={PendingAppointmentIcon} />
            </div>
            <div class="text-right">
              
              <p className='gray-700'>Pending Appointments</p>
              <p class="text-3xl font-bold text-pink-400">{pending}</p>
            </div>
          </div>
          
          
        </div>

        <div className='flex md:flex-row flex-col space-x-3'>
          <div className='flex flex-col md:w-1/4'>
            <div className='font-bold'>
              My Details

            </div>
            <div className='rounded-lg flex flex-col p-3 bg-white'>
              <div className='flex flex-row space-x-3'>
                <div>
                <div class="relative w-16 h-16">
                    <img class="rounded-full border-2 border-blue-500 shadow-sm" src={PatientIcon} alt="user image" />
                    <div class="absolute top-0 right-0 h-4 w-4 my-1 border-2 border-white rounded-full bg-green-400 z-2"></div>
                </div>
                  
                </div>
                <div className='flex flex-col'>
                  <div className='font-bold'>
                    Name
                  </div>
                  <div>
                    Address
                  </div>
                  <div>
                    City
                  </div>

                </div>

              </div>
              <div className='space-y-2'>
                <div className='flex flex-row space-x-3'>
                  <div className='flex flex-col w-1/2'>
                    <div className='font-bold'>
                      D.O.B
                    </div>
                    <div className='text-gray-700 text-sm'>
                    D.O.B
                    </div>

                  </div>
                  <div className='flex flex-col w-1/2'>
                    <div className='font-bold'>
                      Sex
                    </div>
                    <div className='text-gray-700 text-sm'>
                    Sex
                    </div>

                  </div>

                </div>
                <div className='flex flex-row space-x-3'>
                  <div className='flex flex-col w-1/2'>
                    <div className='font-bold'>
                     Height
                    </div>
                    <div className='text-gray-700 text-sm'>
                    D.O.B
                    </div>

                  </div>
                  <div className='flex flex-col w-1/2'>
                    <div className='text-sm font-bold'>
                      Register Date
                    </div>
                    <div className='text-gray-700 text-sm'>
                    Date
                    </div>

                  </div>

                </div>
                <div className='flex flex-row space-x-3'>
                  <div className='flex flex-col w-1/2'>
                    <div className='text-sm font-bold'>
                     Last Appointment
                    </div>
                    <div className='text-gray-700 text-sm'>
                    date
                    </div>

                  </div>
                  <div className='flex flex-col w-1/2'>
                    <div className='font-bold'>
                      Weight
                    </div>
                    <div className='text-gray-700 text-sm'>
                    Weight
                    </div>

                  </div>

                </div>
              </div>

            </div>

          </div>

          <div className='flex flex-col w-1/2'>
            <div className='font-bold'>
              Appointments
            </div>
            <div className='rounded-lg bg-white p-3 flex flex-col'>
              {/* end */}
              {appointments}
              
            </div>
            

          </div>
          <div className='flex flex-col md:w-1/4'>
            <div className='font-bold'>
              Available Doctors
            </div>
            <div className='rounded-lg bg-white p-3'>
            <div>
              {renderdoctor}
              </div>
            </div>

          </div>

        </div>
      
      

      </div>
      
    </div>
  </div>    

  
     );
}
 
export default Dashboard;