// const url = "https://cl-api.docsun.health/api/"

// const url = "https://cl-api.docsun.health/api/" //< --clinical server
// const url = "https://diagnostic-api.bostonresearch.ai/api/" <-- api for boston research
const url = "https://api.docsun.health/api/" //<-- prod api

// const url = "https://diagnostic-api-test.bostonresearch.ai/api/" //<-- test api

export const stressurl="https://patient.stress.docsun.health";
export const basicurl="https://patient.basic.docsun.health";
export const bsurl="https://patient.insulin.docsun.health";
export const clurl="https://patient.cholesterol.docsun.health";

// export const stressurl="http://localhost:3000";
// export const basicurl="http://localhost:3000";
// export const bsurl="http://localhost:3000";
// export const clurl="http://localhost:3000";

export default url