import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';

import DocNav from './DocNav';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import url from '../url';
import { AuthContext } from '../../App';
import BlueAppointIcon from '../../images/UserHMSAssets/Appointments1@2.1x.png'
import GrayppointIcon from '../../images/UserHMSAssets/Appointments2@2.1x.png'
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import DocIcon from '../../images/UserHMSAssets/Doctor Icons.png'

const Appointment = () => {
    const [appointmentdate, setAppointmentDate] = useState('');
    const [doctors,setDoctors]=useState([])
    const [doctorname,setDoctorName]=useState('')
    const [doctorid,setDoctorId]=useState('')
    const [value, onChange] = useState(new Date());
    const [error, setError]=useState('')
    const [success,setSucces]=useState(false)

    const [spin,setSpin]=useState(false)

    const {auth}=useContext(AuthContext)
    // console.log(auth.userid)

    // const onChange=()=>{

    // }

    useEffect(()=>{
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.get(url+ 'hms/doctor/doctors',config).then(res=>{
            console.log(res)
            setDoctors(res.data.result.data)
        }).catch(e=>{

            console.log(e)

        })

    },[])

    const renderdoctor=doctors.map((doctor,index)=>(
        <div key={index} className="flex flex-row space-x-4 py-2">
            <div>
            <div class="relative w-16 h-16">
                                    <img class="rounded-full border-2 border-blue-500 shadow-sm" src={DocIcon} alt="user image" />
                                    <div class="absolute top-0 right-0 h-4 w-4 my-1 border-2 border-white rounded-full bg-green-400 z-2"></div>
                                </div>
            </div>
            <div className='flex flex-col w-1/2'>
                <div>
                {doctor.name}

                </div>
                <div className='text-sm'>
                Family Medicine

                </div>
                <div>
                {/* <div class="flex items-center text-sm" >
                    <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                    <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                    <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                    <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                    <svg class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                </div> */}

                </div>
                
            </div>
            <div>
            <div class="relative w-10 h-10">
            
            <button onClick={()=>selectDoctor(doctor.id,doctor.name)}>
            <img src={BlueAppointIcon} />

            </button>

            </div>
                
            </div>


        </div>
    ))
    const selectDoctor=(id,name)=>{
        setDoctorName(name)
        setDoctorId(id)


    }
    const configureDate=(value,event)=>{

        // alert(value)
        // alert()
        
        
        setAppointmentDate(moment(value).format("dddd, D MMM  YYYY"))
        

    }

    const cancel=()=>{
        // alert('cancelled')
        setAppointmentDate('')
        setDoctorName('')
        setDoctorId('')

    }
    const schedule=()=>{

        setError('')

        let value= document.getElementById('time-input').value

        if(!value){
            // alert('weka time')
            setError('Enter time')

            return
        }

        setSpin(true)
        
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };

        let appdate=moment(appointmentdate).format("YYYY-MM-D")

        // return

        const params = {
            doctor_id: doctorid, 
            patient_id: auth.userid, 
            appointment_date:appdate+' '+value
         };
        axios.post(url+'hms/doctor/send',params,config)
        .then(res=>{
            // console.log(res)
            // alert(res.data.result.body)
            setSucces(true)

        }).catch(e=>{
            console.log(e)
            setError(e.message)

        }).then(r=>{
            console.log(r)
            setSpin(false)

        })

        
    }
    return ( 
        <div>
            {success?<>
            <Navigate to='/dashboard' />
            </>:null}
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white  text-black ">

      {/* Navigation Here */}
      <DocNav />
    
      
      <div  class="h-full ml-14 mt-14 mt-18 md:ml-64 px-4 flex flex-col">
          {/* Main content here */}
      {/* <MainContent /> */}

      <div className='text-4xl font-bold'>
        <br />
      Schedule an Appointment
      </div>
      
      <div className='flex md:flex-row flex-col space-x-3'>
          <div className='flex flex-col md:w-1/3'>
              <div className='m-3 font-bold'>
                  Calendar
                  
              </div>
              <div>
              <Calendar onClickDay={(value,e)=>configureDate(value,e)} onChange={onChange}   value={value} />
              {/* onChange */}
              </div>
          

          </div>
          <div className='flex flex-col md:w-1/3'>
              <div className='m-3 font-bold'>
              Scheduler
                  
              </div>
              <div className='flex flex-col'>
                <div class="mb-2">
                    <label for="base-input" class="block mb-2 text-sm font-medium text-gray-900 ">Select Doctor</label>
                    <input type="text" id="base-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  " value={doctorname} readOnly />
                </div>
                <div class="mb-2">
                    <label for="base-input" class="block mb-2 text-sm font-medium text-gray-900 ">Select Date</label>
                    <input type="text" id="base-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  " value={appointmentdate} readOnly />
                </div>
                <div class="mb-2">
                    <label for="base-input" class="block mb-2 text-sm font-medium text-gray-900 ">Choose time</label>
                    <input type="time" id="time-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "  />
                </div>
                <div class="mb-6">
                <span class="flex items-center font-medium tracking-wide text-red-500  mt-1 ml-1">
                {error}
            </span>
                </div>
                <div class="mb-6">
                {spin?<>
                    <svg role="status" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            </>:null}
                </div>
                <div className="flex flex-row space-x-3">
                <button type="button" class="text-black bg-blue-300 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 " onClick={cancel}>Cancel</button>
                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 " onClick={schedule}>Schedule</button>

                </div>
              </div>
          

          </div>
          <div className='flex flex-col md:w-1/3'>
              <div className='m-3 font-bold'>
                  Available Doctors
                  
              </div>
              <div className=''>
              {renderdoctor}
              </div>
          

          </div>
      

      </div>

      </div>
      
    </div>
  </div>    

  
     );
}
 
export default Appointment;