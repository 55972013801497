import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import BasicIcon from '../../images/Basic icon.png'
import SugarIcon from '../../images/SugarIcons.png'
import BpIcon from '../../images/BP Icons.png'
import CholIcon from '../../images/Cholerestrol Icons.png'
import { AuthContext } from '../../App';

const Landing = () => {
    // alert('hey')

    // console.log('yes yes')

    const {auth}= useContext(AuthContext)
    const user= JSON.parse(auth.user)
    const dt= Date()
    // console.log(user)
    // alert(user.id)
    // alert(user.accessToken)
    
    return (<>
   <div class="font-ssans flex h-screen bg-white">
    <div class="mx-auto md:m-8 space-y-6 w-full md:w-full flex">
        <div className=' flex flex-col mx-auto'>
            
        <div className='flex flex-col flex-auto md:mx-36 my-32 border-white border-4  bg-blue-200 mx-auto shadow-2xl shadow-gray-800  pt-20 '>
            {/* remove pt-14 */}
            {/* Change above to accommade other links */}
            <div className='border-2 rounded-lg bg-gray-700 md:m-5 mx-auto flex '>
                <div className=' flex flex-col mx-auto'>
                    <div className='mx-auto text-white text-2xl font-bold mb-4 mt-2'>
                        Discover

                    </div>
                    <div className='flex flex-col md:mx-16 mx-2 space-y-4'>
                    <div className='bg-black rounded-lg flex flex-col text-white font-bold px-3 '>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BasicIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Heart Rate, Breathing Rate, and SpO2 Levels

                            </div>
                            
                        </div>
                        
                            <a href='https://patient-test.basic.docsun.health/dashboard'>
                            <div className='flex flex-row'>
                            
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>
                            </div>
                            </a>

                        

                    </div>
                    {/* <div className='bg-black rounded-lg flex flex-col text-white font-bold'>
                        <div className='flex flex-row'>
                            <div>
                                <img src={SugarIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Blood Sugar Level

                            </div>
                            
                        </div>
                        <a href={`https://patient-test.insulin.docsun.health/${user.accessToken}/${dt}/${user.id}`}>
                        <div className='flex flex-row ml-4'>
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>

                        </div>
                        </a>

                    </div> */}
                    <div className='bg-black rounded-lg flex flex-col text-white font-bold px-3'>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BpIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Blood Pressure

                            </div>
                            
                        </div>
                        <a href={`https://patient-test.stress.docsun.health/${user.accessToken}/${dt}/${user.id}`}>
                        <div className='flex flex-row'>
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>

                        </div>
                        </a>

                    </div>
                    {/* <div className='bg-black rounded-lg flex flex-col text-white font-bold px-3 my-4'>
                        <div className='flex flex-row'>
                            <div>
                                <img src={CholIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Cholesterol Level

                            </div>
                            
                        </div>
                        <a href={`https://patient-test.cholesterol.docsun.health/${user.accessToken}/${dt}/${user.id}`}>
                        <div className='flex flex-row'>
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>

                        </div>
                        </a>
                     
                    </div> */}
                    &nbsp;
                    </div>
                </div>

            </div>
        
        </div>
        {/* <div className=' mx-20 flex flex-col text-sm'>
       <div> Instructions to Follow For Accurate Scanning : </div>
       <ul className='list-disc ml-5'>
        <li> Please load the page and enable the internal or external camera to scan.</li>
        <li> Position the camera accordingly in front of you and perform the scan in a well-lit environment.</li>
        <li> Do not do the scan under too much backlight.</li>
        <li> Adjust your face position to the camera until the green frame is steadily shown and please stay still.</li>
        <li> Remove facial accessories such as masks, eyeglasses, and contact lenses for better accurate detection.</li>
        <li> The scan lasts until the health status shows up on the screen. You can click “Stop Scanning” to see your stable result.</li>

       </ul>

        </div> */}


        </div>
        
    </div>
</div>
    </>  );
}
 
export default Landing;