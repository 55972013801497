import React,{useEffect, useState,useContext} from 'react';
import { Link } from 'react-router-dom';
// import RecordVideo from './RecordVideo';
import Spo2 from '../module1/preview/Screen2-VitalSign1.png'
import HeartRate from '../module1/preview/Screen2-VitalSign2.png'
import BsRate from '../module1/preview/Screen2-VitalSign3.png'
// import Temp from '../module1/preview/Screen2-VitalSign4.png'
import BPressure from '../module1/preview/Screen2-VitalSign5.png'
// import EyeColor from '../module1/preview/Screen2-VitalSign6.png'
import Breathing from '../module1/preview/Screen2-VitalSign7.png'
import Healthy from '../module1/WebAppModule1_Screen5Green.png' 
import Abnormal from '../module1/preview/Screen5-CautionIcon.png' 
import LoadingIcon from '../module1/preview/Screen2-LargerLoadingIcon.gif' 
import HealthyMarker from '../module1/preview/Screen3-HealthyMarker.png' 
import AbnormalMarker from '../module1/preview/Screen5-CautionMarker.png' 
import CautionMarker from '../module1/preview/Screen4-AbnormalMarker.png' 
// import AbnormalMarker from '../module1/preview/Screen5-CautionMarker.png' 
import Caution from '../module1/WebAppModule1_Screen6Yellow.png'
import V2 from '../module1/TabBar-Wellness@2x.png'
import Health from '../module1/TabBar-Healthiness@2x.png'
import Wellness from '../module1/TabBar-Wellness_1@2x.png'
// import 'react-notifications/lib/notifications.css';
// import {NotificationContainer, NotificationManager} from 'react-notifications';

import SkipVideo from '../images/SkipVid.mp4'

import Smile from  '../module1/WebAppModule1_Screen8PopSmile.png'
// import { useContext } from 'react/cjs/react.production.min';
// import { AuthContext } from '../App';  
// import 

import HeartIcon from '../images/icons/HR.png'
import BRIcon from '../images/icons/BR.png'
import SpO2Icon from '../images/icons/Spo2.png'
import BPIcon from '../images/icons/BP.png'
import BSIcon from '../images/icons/Sugar.png'
import CLIcon from '../images/icons/Cholesterol.png'
import GreenBox from '../images/UserHMSAssets/animation/Green box.png'
import NoMask from '../images/UserHMSAssets/animation/No mask.png'
import NoClasses from '../images/UserHMSAssets/animation/No glasses.png'
import LightUp from '../images/UserHMSAssets/animation/Lit up bg.png'
import AnimatedText from 'react-animated-text-content';

const Security = () => {
    // console.log( sessionStorage.getItem('userid'))
    // alert(sessionStorage.getItem('userid'))
    // https://codesandbox.io/s/zhde4?file=/src/App.js
    const [healthstatus, setHealthStatus] = useState('')
    const [icon, setIcon]=useState('')
    const [img, setImg]=useState(LoadingIcon)
    const [greenb,setGreenB]=useState('')
    const [nom,setNoM]=useState('')
    const [noc,setNoC]=useState('')
    const [lu,setLu]=useState('')
    const [animatetext, setAnimateText]=useState('')
    const [startani, setStartAni]=useState(0)
    const [skipdisplay,setSkipDelay]=useState('inline')
    const [scanui,setScanUI]=useState('hidden')


   const toggleMe=()=>{

    setSkipDelay('hidden')
    setScanUI('inline')


    // if(skipdisplay=='hidden'){
    //   setSkipDelay('inline')

    // }else{
    //   setSkipDelay('hidden')

    // }
   }

    

    useEffect(() => {
        const timerg = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        setGreenB(GreenBox)
        }, 1000);
        return () => clearTimeout(timerg);
      }, []);
    useEffect(() => {
        const timern = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        setNoM(NoMask)
        }, 2500);
        return () => clearTimeout(timern);
      }, []);
    useEffect(() => {
        const timerc = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        setNoC(NoClasses)
        }, 4000);
        return () => clearTimeout(timerc);
      }, []);
    useEffect(() => {
        const timerlu = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        setLu(LightUp)
        }, 5000);
        return () => clearTimeout(timerlu);
      }, []);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        if(startani==1){
          // setAnimateText('Please wait...')
          setAnimateText('Look at the camera...')

        }
        
        }, 1000);
        return () => clearTimeout(timerls);
      }, [startani]);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        if(startani==1){
          setAnimateText('Stay Still...')

        }
        
        }, 2500);
        return () => clearTimeout(timerls);
      }, [startani]);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        if(startani==1){
          setAnimateText('Hold On...')

        }
        
        
        }, 4000);
        return () => clearTimeout(timerls);
      }, [startani]);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        
        if(startani==1){
          setAnimateText('Wait a second...')

        }
        }, 5000);
        return () => clearTimeout(timerls);
      }, [startani]);
    useEffect(() => {
        const timerls = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        
        if(startani==1){
          setAnimateText('')
          setStartAni(0)

        }
        }, 6000);
        return () => clearTimeout(timerls);
      }, [startani]);
    
    // const { auth, dispatchAuth } = useContext(AuthContext)
    //  const { auth, dispatchAuth } = useContext(AuthContext)
    useEffect(() => {
        document.getElementById("scan").click();

        // return () => {
        //     document.getElementById("stop").click();
            
        // }
        
    }, [])

     useEffect(() => {
  const interval = setInterval(() => {
    // console.log('This will run every second!');
      
      getVal()
  }, 2000);
  return () => clearInterval(interval);
    }, []);

    useEffect(()=>{
        setIcon('')
        setImg('')

    },[])

    useEffect(()=>{
        renderstatus()

    },[healthstatus])


    const getVal=()=>{
       var status= document.getElementById("diag_value").value

    //    document.getElementById("demo").innerHTML = status;

       console.log(status)
       if(status!=healthstatus){
        setHealthStatus(status)
       }
    }


    const renderstatus=()=>{

        if(healthstatus==1){
                setImg(Healthy)
                setIcon(HealthyMarker)
            

            return 

        }else if(healthstatus==2){
            //LoadingIcon
            // caution
            setImg(Caution)
            setIcon(CautionMarker)
        }
        else if(healthstatus==3){
            //LoadingIcon
            // abnormal
            setImg(Abnormal)
            setImg(AbnormalMarker)
        }else{
            setImg(LoadingIcon)
            setIcon('')

        }


    }
    


    
   
    const reClick = () => {
        // alert('hello');
        // document.getElementById("scan").click();
        // document.getElementById("scan").click();

        // NotificationManager.info('Loading....');
        

        // openDialog2()


        

        // setTimeout(function () {
        //     reClick();
        // }, 5000);
    }

    const renderanimation=()=>(
        <AnimatedText
  type="chars" // animate words or chars
  animation={{
    x: '200px',
    y: '-20px',
    scale: 1.1,
    ease: 'ease-in-out',
  }}
  animationType="lights"
  interval={0.1}
  duration={0.1}
  tag="p"
  className="animated-paragraph"
  includeWhiteSpaces
  threshold={0.1}
  rootMargin="20%"
>
  {animatetext}
</AnimatedText>
    )
    
    return (<div className='antialiased bg-white font-ssans flex flex-col'>
        {/* <div class=" flex  md:flex-row  mx-auto m-8 space-y-6"> */}
       
        <div class=" flex  md:flex-row space-y-0 flex-col ">
            <div className=' my-4 space-y-0 mr-4'>
               <div className='md:pl-10 flex md:flex-col'>
                {greenb?<>
                  <img src={greenb} className=" md:h-32 md:w-32  h-20 w-20 " alt='' />
                </>:null}
                {nom?<>
                  <img src={nom} className=" md:h-32 md:w-32  h-20 w-20" alt='' />
                </>:null}
                {noc?<>
                  <img src={noc} className=" md:h-32 md:w-32  h-20 w-20" alt='' />
                </>:null}
                {lu?<>
                  <img src={lu} className="md:h-32 md:w-32  h-20 w-20" alt='' />
                </>:null}
                
                
                
                
                </div> 
            </div>
            
            <div className=' my-8 space-y-6 md:mr-72'>
                <div id="vital_history" > 
                </div>
                {/* <button onClick={()=>toggleMe()}>Toggle </button> */}
                
                    <br />
                    
                    <span className={`wrapper ${skipdisplay}`}>
                    <div className='my-2'><button onClick={()=>toggleMe()} type="button" class=" md:inline hidden text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Skip Video</button></div>
                    {/* <iframe class="w-fill aspect-video ... ml-3" src={SkipVideo} width="640"  height="480"></iframe> */}
                    <iframe class="w-full aspect-video  ml-3  md:w-[40rem] md:h-[30rem]" src={SkipVideo} ></iframe>

                    <div className='my-2'><button onClick={()=>toggleMe()} type="button" class=" ml-2 md:hidden inline text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Skip Video</button></div>

                    </span>
                    
                <div className={`wrapper ${scanui}`}>
                <h2 id="checker" hidden>Modules Loading</h2>
                <p hidden>FPS : <span id='fps_msg'></span></p>
                  
                <div>
                    <div className="navi bg-black border-white shadow-black shadow-2xl border-2">
                    <div className='progress__container' hidden>
                        <div id="progress__value"hidden></div>
                    </div>
                    <video hidden id="video"  width="640" className='w-full' height="480" autoPlay></video>
                    <canvas id="canvas"  width="640" className='w-full' height="480"></canvas>
                    
                    
                    </div>
                </div>
                <div className=" w-full h-full grid grid-flow-row grid-cols-2" id="infoi">

                  <div className="flex flex-col md:m-4 justify-between m-2">
                      {/* Left part */}
                        <div className='flex flex-col justify-between'>

                            <div>
                            <div className="bg-no-repeat  md:h-28 h-[4.5rem] bg-contain" style={{ backgroundImage: `url(${Spo2})`}} >
                       {/* < br /> */}
                       {/* < br /> */}
                          <div className='flex flex-col'>
                          <input  className=" bg-inherit border-0 w-12 md:mx-8 mx-4 md:mt-10 mt-5 text-white md:text-3xl text-2xl text-extrabold font-black" readOnly id="spo2" /> 
                          <div className=' md:mt-1 mt-0'>
                          <span className='text-white font-bold md:ml-10 ml-5 text-sm'>%</span>
                            </div>
                          </div>

                          
                      </div>
                      <span className="text-white md:font-bold font-semibold  ml-2" >SpO2 Levels</span>
                      <div className="bg-no-repeat md:h-28 h-[4.5rem] bg-contain" style={{ backgroundImage: `url(${HeartRate})`}} >
                          {/* < br />
                          < br /> */}
                          <div className='flex flex-col'>
                          <input type="text" className=" bg-inherit border-0 w-16 md:mx-8 mx-4 md:mt-10 mt-5 text-2xl text-white text-extrabold font-black" readOnly id="heart_rate"  />
                          <div className=' md:mt-1 mt-0'>
                        <span className='text-white font-bold ml-5 md:mt-18 mt-0 text-sm'>BPM</span>
                        </div>

                          </div>
                        
                      </div>
                      <span className=" text-white font-bold ml-3" >Heart Rate</span>
                      {/* <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${BsRate})`}} >
                          < br />
                        <input className=" bg-inherit border-0 w-10 mx-6 text-white text-2xl text-extrabold font-black mt-1" readOnly id="bs_rate" /> 
                        <br />
                        <span className='text-white font-bold ml-3'>mg/dL</span> 
                      </div>
                        <span className="text-sm text-white font-bold" >Blood Sugar</span> */}
                            </div>
                            

                        </div>
                        <div className='text-blue-500 text-sm flex flex-col w-full'>
                            {/* <button className="btn btn-primary">
                            <div className="flex flex-row">
                                            <div className="w-1/2 flex-row">
                                            <i class="fa fa-arrow-left" aria-hidden="true" />
                                            </div>
                                            <div className="w-1/2 flex">
                                                Back to Dashboard
                                            </div>
                                        </div>
                                </button> */}
                               
                                {/* <Link to='/landing'>
                                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                    <div className="flex flex-row w-3/4">
                                            <div className="">
                                            <i class="fa fa-arrow-left h-10" aria-hidden="true" />
                                            </div>
                                            <div className="">
                                                Back to Dashboard
                                            </div>
                                        </div>
                                     
                                </button>
                                </Link> */}
                                <div className='text-white md:text-2xl  text-sm font-semibold'>{renderanimation()}</div>
                                <button id="start_stop_btn" className='btn btn-info text-white rounded-lg' value="1" onClick={()=>setStartAni(1)}>CLICK HERE TO START VITAL SCANNING</button>
                                
                            </div>
                      
                  </div>
                  <div className="flex flex-row-reverse mt-2">
                            {/* right part */}
                            

                      <div className="flex flex-col">
                          <div>
                          {/* <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${BPressure})`}} >
                          < br />
                          
                            <span className="mt-10 text-white"> <input className=" bg-inherit border-0 w-10 text-white text-extrabold font-black mt-1"  readOnly id="syst_rate" /> <b>- </b> / <input className=" bg-inherit border-0 w-10 text-white text-extrabold font-black mt-1" readOnly id="diast_rate" />  </span>
                            <br /> <span className='text-white font-bold ml-6'>mmHg</span> 
                          
                       
                      </div>
                      <span className="text-sm text-white font-bold mr-3" >Blood Pressure</span> */}
                      

                      </div>
                      <div>
                          

                      </div>
                      
                      <div>
                          <div className="bg-no-repeat h-[4.6rem] md:h-28 bg-contain mr-4" style={{ backgroundImage: `url(${Breathing})`}} >
                          {/* <img src={icon} className=" h-3 ml-12 mb-2" /> */}
                        
                        <div className='hidden md:inline'>
                        < br />
                          <br /> 
                          </div> 
                         
                          
                            <span className=" mt-10 text-white"> <input className=" bg-inherit border-0 w-12 mx-8 md:ml-10 ml-5 text-white md:text-3xl text-2xl text-extrabold font-black md:mt-1 mt-6" readOnly id="resp_rate" /> </span>
                            <br /> 
                            
                            {/* <br />  */}
                            <span className='text-white text-sm font-bold md:ml-10 ml-5 md:mt-18'>RPM</span> 
                          
                          
                      </div>
                      <span className=" text-white font-bold text-1xl" >Breathing Rate</span>

                      </div>
                      {/* Blood Pressure Module Elements */}
                      <div className="scan__Box" hidden>
                          <label htmlFor='blood_pressure'>Blood Pressure</label><br/>
                          <input type="text" id="blood_pressure"/>  
                      </div>
                      {/* Blood Sugar Module Elements */}
                      <div className="scan__Box" hidden>
                          <label htmlFor='bs_rate'>Blood Sugar</label><br/>
                          <input type="text"  id="bs_rate"/>
                      </div>
                      {/* Blood Cholesterol Module Elements */}
                      <div className="scan__Box" hidden>
                          <label htmlFor='chol_rate'>Blood Cholesterol</label><br/>
                          <input type="text"  id="chol_rate"/>
                      </div>
                      {/* Use to get Information about the modules */}
                      <input hidden id='check_basic' readOnly/>
                      <input hidden id='check_bp' readOnly/>
                      <input hidden id='check_bs' readOnly/>
                      <input hidden id='check_bc' readOnly/>
                      <div hidden>
                          <img src={img} className=" h-20 mr-4" />

                      </div>

                      </div>
                      
                      
                  </div>

              </div>
                </div>
                <div className="flex m-3">
                    <div className='space-x-4 flex flex-row'>
                    <button hidden id="scan" className='btn hidden'>Start Idle Scanning</button>
                    {/* <button id="real_scan" className='btn btn-info text-white rounded-lg'>Start Vital Scanning</button> */}
                    {/* <button id="stop"  hidden className='btn btn-secondary bg-red-500 rounded-lg text-white'>Stop Vital Scanning</button> */}
                    <button id="stop"  hidden >Stop Vital Scanning</button>
                    <div className='flex flex-col'>
                    <img src={SpO2Icon} className="h-12 w-10" />
                    <span>
                    <span className='font-bold'> Sp02</span><br />+/- 2 %
                    </span>

                    </div>
                    <div className='flex flex-col mx-auto items-center'>
                    <img src={HeartIcon} className="h-12 w-10 items-center" />
                    <div>
                        <span className='font-bold'>Heart Rate</span><br />+/- 5 BPM
                    </div>
                    

                    </div>
                    <div className='flex flex-col items-center'>
                    <img src={BRIcon} className="h-12 w-12" />
                    <span>
                        <span className='font-bold'>Breathing Rate</span><br />+/- 5 RPM
                    </span>

                    </div>
                    </div>
                </div>
                <p>Message : <span id='prog_dynamic'></span></p>
                
  
  
                <h4 hidden> Result :  <input type="text" id="diagnosis" className='text-3xl font-black'  hidden/><input type="text" hidden id="diag_value" /><input  type="text" hidden id="hr_diag_score"/><input hidden type="text"  id="br_diag_score"/><input  type="text" hidden id="spo2_diag_score"/></h4>
        
            </div>
            
            
            </div> 
        
        
  
       
    </div>)
}
 
export default Security;