import { JaaSMeeting, JitsiMeeting } from '@jitsi/react-sdk';
import React,{useContext, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import uuid from 'react-uuid'
import { AuthContext } from '../../App';
import BpIcon from '../../images/BP Icons.png'
import BasicIcon from '../../images/Basic icon.png'
import { basicurl, stressurl } from '../url';

const JitsiSDK = () => {
    const dt= Date()
    let { user,doctor,meetingid } = useParams();

    const {auth}= useContext(AuthContext)
    const user2= JSON.parse(auth.user)

    

    useEffect(()=>{

        // alert('changed')

        

        // console.log('hello')

        // alert(uuid())

    },[])


    return ( <>



        <div className='my-3'>
        <div className='flex md:flex-row  flex-col md:space-x-3 space-y-2 mx-2 mt-5 md:space-y-0'>
      <div className='bg-gray-700 rounded-lg flex flex-col text-white font-bold px-3 '>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BasicIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Heart Rate, Breathing Rate, and SpO2 Levels

                            </div>
                            
                        </div>
                        
                            <a href={`${basicurl}/scan-results`} target="_blank">
                            <div className='flex flex-row'>
                            
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>
                            </div>
                            </a>

                        

                    </div>
                    <div className='bg-gray-700 rounded-lg flex flex-col text-white font-bold px-3'>
                        <div className='flex flex-row'>
                            <div>
                                <img src={BpIcon} className="h-12" />
                                
                                
                            </div>
                            <div className=' mt-2'>
                                Check Blood Pressure

                            </div>
                            
                        </div>
                        <a href={`https://${stressurl}/${auth.token}/${dt}/${auth.userid}`} target="_blank">
                        <div className='flex flex-row'>
                            <div className='text-blue-500'>
                                Get Started
                                
                            </div>
                            <div className='text-blue-500 ml-3'>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>

                            </div>

                        </div>
                        </a>

                    </div>
                   

      </div>
       

        </div>
   

<JitsiMeeting
    // domain = { 'doctor-test.docsun.health' }
    roomName = {meetingid}
    configOverwrite = {{
        startWithAudioMuted: true,
        disableModeratorIndicator: true,
        startScreenSharing: true,
        enableEmailInStats: false
    }}
    interfaceConfigOverwrite = {{
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
    }}
    userInfo = {{
        displayName: user2.name
    }}
    onApiReady = { (externalApi) => {

        console.log(externalApi)
        // here you can attach custom event listeners to the Jitsi Meet External API
        // you can also store it locally to execute commands
    } }
    // getIFrameRef = { (iframe) => { iframeRef.style.height = 400; } }
    getIFrameRef = { node => (node.style.height = '500px',node.style.width = '100%') }
/>

    
    </> );
}
 
export default JitsiSDK;