import logo from './logo.svg';
import './App.css';
import Scan from './components/Scan';
import { Routes, Route, Link } from "react-router-dom";
import Security from './components/Security';
import Nav from './components/Nav';
import Basic from './components/Basic';
import Login from './components/auth/Login';
import SignUp from './components/auth/SingUp';
import Logout from './components/auth/Logout';
import Landing from './components/auth/Landing';
import { createContext, useReducer } from 'react';
import Instructions from './components/Instructions';
import JitsiSDK from './components/Conference/JitsiSDK';
import DocBlank from './components/patient/DocBlank';
import Dashboard from './components/patient/Dashboard';
import Meeting from './components/patient/Meeting';
import ScanResults from './components/patient/ScanResults';
import Appointment from './components/patient/Appointment';
import Home from './components/patient/Home';

export const AuthContext = createContext()

const initialauth = {
  logged_in: sessionStorage.getItem('logged_in',false),
   user: sessionStorage.getItem('user'),
   token: sessionStorage.getItem('token'),
   userid: sessionStorage.getItem('userid'),
}

const authreducer = (state, action) => {
  switch (action.type) {

    
    case 'change_language':
     
          return {...state,preffered_language : action.value}
    case 'login_status':
          return {...state,logged_in : action.value}
    case 'user':
      return {...state, user: action.value}
    case 'token':
      return {...state, token: action.value}
    case 'userid':
      return {...state, userid: action.value}
    
    default:
      return state
  }
};

function App() {
  const [auth, dispatchAuth] = useReducer(authreducer, initialauth)
  return (
    <AuthContext.Provider value={{ auth, dispatchAuth }}>
    <div>
      {/* <Scan /> */}
      {/* <Nav /> */}
      <Routes>
      <Route path="/" element={<Login />} />
      <Route path='/login' element={<Login />} />
      <Route path='/signup' element={<SignUp />} />
      <Route path='/landing' element={<Landing />} />
      <Route path='/scan' element={<Security />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/instructions' element={<Instructions />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/blank' element={<DocBlank />} />
      <Route path='/appointments' element={<Appointment />} />
      <Route path='/scan-results' element={<ScanResults />} />
      <Route path='/dashboard/:key/:dt/:uid' element={<Home />} />
      <Route path='/meeting/:user/:doctor/:meetingid' element={<Meeting />} />

        {/* <Route path="/security" element={<Security />} /> */}
      </Routes>
     
    </div>

    </AuthContext.Provider>
    
  );
}

export default App;
