import React,{useEffect, useState} from 'react';
// import ProgressBar from "@ramonak/react-progress-bar";


import HeartIcon from '../images/icons/HR.png'
import BRIcon from '../images/icons/BR.png'
import SpO2Icon from '../images/icons/Spo2.png'
import BPIcon from '../images/icons/BP.png'
import BSIcon from '../images/icons/Sugar.png'
import CLIcon from '../images/icons/Cholesterol.png'
var ProgressBar = require('progressbar.js')

const Scan = () => {
  const [hrscore,setHrScore]=useState(0)
  const [brscore, setBrScore]=useState(0)
  const [spo2score, setSpo2Score]=useState(0)

  useEffect(() => {
      // Number from 0.0 to 1.0
    const interval = setInterval(() => {
      // console.log('This will run every second!');

        getVal()
    }, 1000);
    return () => clearInterval(interval);
      }, []);

      const getVal=()=>{
        var hscore= document.getElementById("hr_diag_score").value
        var bscore= document.getElementById("br_diag_score").value
        var sscore= document.getElementById("spo2_diag_score").value
        if(hrscore!=hscore){

          var bar = new ProgressBar.Path('#heart-path', {
            easing: 'easeInOut',
            duration: 1400
          });
          
          bar.set(hscore/100);
          // bar.animate(hscore/100);

          setHrScore(hscore)
        }
        if(brscore!=bscore){
          setBrScore(bscore)
        }
        if(spo2score!=sscore){
          setSpo2Score(sscore)
        }
      }

    return (<>
    {/* <div className='text-2xl font-bold text-center py-3 border-4'>Docsun Real Time Detection</div> */}


    <div className='flex md:flex-row flex-col w-full bg-black'>
      <div className='flex bg-black'>
        {/* Video */}

        {/* <video hidden id="webcam" width="640" height="480" autoPlay></video>
        <canvas id="canvas" width="640" height="480"></canvas> */}
        {/* <video hidden id="webcam"  width="640" className='w-full' height="480" autoPlay></video>
        <canvas id="canvas"  width="640" className='w-full' height="480"></canvas> */}
        <video hidden id="webcam" width="640" height="480" autoPlay></video>
      <canvas id="canvas" width="640" height="480"></canvas>
      </div>
      <div className='flex flex-col flex-auto bg-black'>

        {/* Start of one row */}

        <div className='flex flex-row border-y '>
            <div className=' border-r flex flex-row'>
              {/* Chart */}
              {/* <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas id="hr_canvas" width="320" height="50"></canvas> */}
              <img hidden id="spo2_img" src="./vitalbox.png" alt="" />
              <canvas class="spo2_canvas" width="480" height="130"></canvas>
              <canvas class="spo2_canvas" width="480" height="130"></canvas>

            </div>
            <div className='flex'>
              {/* value */}
              <div className=' text-green-500 flex flex-row'>

                <div className='flex flex-col mt-1 w-full'>
                  <div className='flex text-2xl font-black'>
                  Sp02 <sub className='text-2xl font-black'> %</sub>
                  {/* Respiratory Rate  <span className='text-xl'>rpm</span> */}

                  </div>
                  <div className='flex flex-row w-full'>
                    <div className='w-1/2'>
                    <input type="text" id="spo2_rate" className='pt-3 bg-black w-16 text-4xl font-black' />

                    </div>

                    {/* <span class="score-label">{spo2score}</span> */}

                  <div class="scorebars">

                  <div class="score-bars" style={ spo2score > 90 ? { background:'green' } : {}}></div>
                  <div class="score-bars" style={ spo2score > 85 ? { background:'green' } : {}}></div>
                  <div class="score-bars" style={ spo2score > 75 ? { background:'green' } : {}}></div>
                  <div class="score-bars" style={ spo2score > 60 ? { background:'#90ee90' } : {}}></div>
                  <div class="score-bars" style={ spo2score > 50 ? { background:'#90ee90' } : {}}></div>
                  <div class="score-bars" style={ spo2score > 40 ? { background:'yellow' } : {}}></div>
                  <div class="score-bars" style={ spo2score > 25 ? { background:'yellow' } : {}}></div>
                  <div class="score-bars" style={ spo2score > 20 ? { background:'red' } : {}}></div>
                  <div class="score-bars" style={ spo2score > 10 ? { background:'red' } : {}}></div>
                  <div class="score-bars" style={ spo2score >= 0 ? { background:'red' } : {}}></div>

                  </div>

                  </div>
                  <div className='flex flex-row font-black justify-between'>
                    <div className='flex'>
                      Max:&nbsp;100

                    </div>
                    <div className='flex ml-8'>
                      Min:&nbsp;60

                    </div>

                  </div>


                </div>
                {/* <div className='text-4xl font-black w-1/3 '>

                  <input type="text" id="spo2_rate" className='pt-3 bg-black w-16 text-4xl font-black' />
                </div> */}
                </div>
            </div>


        </div>  {/* End of one row */}

        {/* Start of one row */}

        <div className='flex flex-row border-b'>
            <div className='border-r flex flex-row'>
              {/* Chart */}
              {/* <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas id="hr_canvas" width="320" height="50"></canvas> */}
              {/* <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas id="hr_canvas" width="320" height="50"></canvas> */}
              {/* <img hidden id="br_img" src="./vitalbox.png" alt="" /> <canvas id="br_canvas" width="320" height="50"></canvas> */}
              {/* <img hidden id="sr_img" src="./vitalbox.png" alt="" /> <canvas id="sr_canvas" width="320" height="130"></canvas> */}
              <img hidden id="br_img" src="./vitalbox.png" alt="" /> <canvas class="br_canvas" width="480" height="130"></canvas>
              <canvas class="br_canvas" width="480" height="130"></canvas>
            </div>
            <div className=''>
              {/* value */}
              <div className=' text-red-500 flex flex-row justify-between '>

                <div className='flex flex-col mt-1'>

                  <div className=' text-2xl font-black'>
                    Respiratory Rate  <span className='text-xl'>rpm</span>
                  </div>
                  <div className='flex flex-row w-full'>
                    <div className='w-full'>
                    <input type="text" id="br_rate" className='pt-3 bg-black w-16 text-4xl font-black' />

                    </div>
                    {/* <span class="score-label">{brscore}</span> */}

                    <div class="scorebars">

                      <div class="score-bars" style={ brscore > 90 ? { background:'red' } : {}}></div>
                      <div class="score-bars" style={ brscore > 85 ? { background:'red' } : {}}></div>
                      <div class="score-bars" style={ brscore > 75 ? { background:'red' } : {}}></div>
                      <div class="score-bars" style={ brscore > 60 ? { background:'yellow' } : {}}></div>
                      <div class="score-bars" style={ brscore > 50 ? { background:'yellow' } : {}}></div>
                      <div class="score-bars" style={ brscore > 40 ? { background:'#90ee90' } : {}}></div>
                      <div class="score-bars" style={ brscore > 25 ? { background:'green' } : {}}></div>
                      <div class="score-bars" style={ brscore > 20 ? { background:'green' } : {}}></div>
                      <div class="score-bars" style={ brscore > 10 ? { background:'green' } : {}}></div>
                      <div class="score-bars" style={ brscore >= 0 ? { background:'green' } : {}}></div>

                    </div>
                  </div>
                  <div className='flex flex-row font-black space-x-9'>
                    <div className='flex w-1/2'>
                      Max:&nbsp;45

                    </div>
                    <div className='flex ml-8 w-1/2'>
                      Min:&nbsp;5

                    </div>

                  </div>




                </div>
                {/* <div className='text-4xl font-black w-1/3  '>

                  <input type="text" id="br_rate" className='pt-3 bg-black w-16 text-4xl font-black' />
                </div> */}
                </div>
            </div>


        </div>  {/* End of one row */}
        {/* Start of one row */}


        {/* Start of one row */}
        <div className='flex flex-row border-b'>
            <div className=' border-r flex flex-row'>
              {/* Chart */}
              {/* <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas id="hr_canvas" width="320" height="50"></canvas> */}
              {/* <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas id="hr_canvas" width="320" height="50"></canvas> */}
              <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas class="hr_canvas" width="480" height="130"></canvas><canvas class="hr_canvas" width="480" height="130"></canvas>
            </div>
            <div className=''>
              {/* value */}
              <div className=' text-blue-500 flex flex-row justify-between  '>

                <div className='flex flex-col mt-1'>
                  <div className='text-2xl font-black'>
                  Heart&nbsp;Rate&nbsp;bpm

                  </div>
                  <div className='flex flex-row w-full'>
                    <div className='flex'>
                    <input type="text" id="heart_rate" className='pt-3 bg-black w-16 text-4xl font-black' />

                    </div>
                    {/* <div class=" bg-gray-200 h-5 mb-6 w-full mt-3"> */}
                    {/* <div class="bg-green-600 h-5" style={{width: `${spo2score}%`}}></div> */}

                    <div id="container">
                      {/* <span class="score-label">{hrscore}</span> */}
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
                  <path fill-opacity="0"  stroke-width="8" stroke="#bbb" d="M81.495,13.923c-11.368-5.261-26.234-0.311-31.489,11.032C44.74,13.612,29.879,8.657,18.511,13.923  C6.402,19.539,0.613,33.883,10.175,50.804c6.792,12.04,18.826,21.111,39.831,37.379c20.993-16.268,33.033-25.344,39.819-37.379  C99.387,33.883,93.598,19.539,81.495,13.923z"/>
                  <path id="heart-path" fill-opacity="0" stroke-width="10" stroke="blue" d="M81.495,13.923c-11.368-5.261-26.234-0.311-31.489,11.032C44.74,13.612,29.879,8.657,18.511,13.923  C6.402,19.539,0.613,33.883,10.175,50.804c6.792,12.04,18.826,21.111,39.831,37.379c20.993-16.268,33.033-25.344,39.819-37.379  C99.387,33.883,93.598,19.539,81.495,13.923z"/>
              </svg>
</div>

                    {/* </div> */}
                    {/* <div class=" bg-gray-200 h-5 mb-6 w-full mt-3">
                    <div class="bg-blue-600 h-5" style={{width: `${hrscore}%`}}></div>
                    </div> */}

                  </div>
                  <div className='flex flex-row font-black space-x-10'>
                    <div className='flex w-1/2'>
                      Max:&nbsp;180

                    </div>
                    <div className='flex'>
                      Min:&nbsp;50

                    </div>

                  </div>


                  {/* <div className= " text-2xl font-black">
                  Heart Rate
                  </div>
                  <div className='flex flex-row'>
                    <div>
                    Max:
                    </div>
                    <div>
                    180
                    </div>


                  </div>
                  <div>
                    Min: 50

                  </div> */}

                </div>
                <div className='text-4xl font-black w-1/3  '>

                  {/* <input type="text" id="heart_rate" className='pt-3 bg-black w-16 text-4xl font-black' /> */}
                </div>
                </div>
            </div>


        </div>  {/* End of one row */}
        {/* Start of one row */}




        {/* End here */}




      </div>




    </div>
    <div className=' flex flex-col text-white bg-black'>

{/* <div>

<h4><b>Instructions to Follow For Accurate Scanning :</b></h4>

<ol className='list-disc ml-4'>
<li>Please load the page and enable the internal or external camera to scan</li>
<li>Position the camera accordingly in front of you and perform the scan in a well-lit environment</li>
<li>Do not do the scan under much backlight</li>
<li>Adjust your face position to the camera until the green frame is steadily shown and please stay still</li>
<li>Remove facial accessories such as masks, eyeglasses, and contact lenses for better accurate detection</li>
<li>The scan lasts untill the health status shows up on the screen. You can click "Stop Scanning" to see your stable results</li>
</ol>

</div> */}


</div>

<div className='hidden'>
<input type="text" id="hr_diag_score" className='pt-3 bg-black w-16 text-4xl font-black' />
            <input type="text" id="br_diag_score" className='pt-3 bg-black w-16 text-4xl font-black' />
            <input type="text" id="spo2_diag_score" className='pt-3 bg-black w-16 text-4xl font-black' />
</div>


    <div className='flex flex-col my-4 w-full'>
          {/* Video Stream */}
          <div className='my-3 flex md:flex-row flex-col'>
            <div className='space-x-4'>



            <button hidden id="scan" className='btn'>Start Idle Scanning</button>
                    <button id="real_scan" className='btn btn-info'>Start Vital Scanning</button>
                    <button id="stop"  className='btn btn-success'>Stop Vital Scanning</button>

            </div>
            <div className='text-2xl'>
            <h4> Diagnosis :  <input type="text" id="diagnosis" /><input type="text" hidden id="diag_value" /></h4>
            </div>

            </div>
          <div className=''>
              {/* <video hidden id="webcam" width="640" height="480" autoPlay></video>
              <canvas id="canvas" width="640" height="480"></canvas> */}

            </div>

      </div>




  <div className='hidden'>
  <img hidden id="spo2_img" src="./vitalbox.png" alt="" /> <canvas class="spo2_canvas" width="480" height="130"></canvas><canvas class="spo2_canvas" width="480" height="130"></canvas>
    <img hidden id="br_img" src="./vitalbox.png" alt="" /> <canvas class="br_canvas" width="480" height="130"></canvas><canvas class="br_canvas" width="480" height="130"></canvas>
    <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas class="hr_canvas" width="480" height="130"></canvas><canvas class="hr_canvas" width="480" height="130"></canvas>
  </div>





{/* <div class="w-full bg-gray-200 h-5 mb-6">
  <div class="bg-blue-600 h-5" style={{width: "25%"}}></div>
</div> */}


    </> );
}

export default Scan;
