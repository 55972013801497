import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../App';
import url from '../url';

const Home = () => {
    let { key,uid } = useParams();
    const [success, setSuccess]=useState(false)
    const {auth, dispatchAuth } =useContext(AuthContext)
    const [tologin, setTologin]=useState(false)

    // alert('hh')

    useEffect(()=>{

        fetchuserObject()
    },[])


    const fetchuserObject=()=>{

        const config = {
            headers: { Authorization: `Bearer ${key}` }
        };
        axios.get(url+'user',config)
        .then(res=>{
            // console.log('my res')
            // console.log(res)
            dispatchAuth({type:'login_status',value:true})
                dispatchAuth({type:'user',value:JSON.stringify(res.data)})
                dispatchAuth({type:'token',value:key})
                dispatchAuth({type:'userid',value:uid})
                // 2. write to session storage
      
                sessionStorage.setItem('logged_in', true);
                sessionStorage.setItem('user', JSON.stringify(res.data));
                sessionStorage.setItem('token', key);
                sessionStorage.setItem('userid', uid);
                setSuccess(true)

        }).catch(e=>{
            console.log(e)
            // alert(e.mess)

            setTologin(true)

        }).then(r=>{

        })
    }
    
    return (<>


    {success?<>
    <Navigate to='/dashboard' />
    
    </>:null}
    {tologin?<>
    <Navigate to='/' />
    
    </>:null}
    
    
    </>  );
}
 
export default Home;